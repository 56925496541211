var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(" " + _vm._s(_vm.$t("msg.CSBL400.002"))),
          _c("span", {
            staticStyle: {
              position: "relative",
              "padding-left": "20px",
              "font-size": "12px",
              color: "#777777",
              "text-align": "right",
            },
            domProps: { innerHTML: _vm._s(_vm.$t("msg.CSDBL400_M2.017")) },
          }),
        ]),
        _c("div", {
          staticStyle: { width: "100%", height: "450px" },
          attrs: { id: "realgridFreeTimePrint" },
        }),
        _c("div", { staticClass: "desc_list" }, [
          _c("ul", [
            _c("li", {
              domProps: { innerHTML: _vm._s(_vm.$t("msg.CSDBL400_M2.019")) },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }