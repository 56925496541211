import Send from '../../settle.client.js'

export default {
    getFreeTime (queryParams) {
        return Send({
          url: '/settle/freetime' + queryParams,
          method: 'get',
          params: {}
        })
      },
    getFreeTimeExtdt (queryParams) {
        return Send({
          url: '/settle/freetime/extdt' + queryParams,
          method: 'get',
          params: {}
        })
    },
    getFreeTimeList (param) {
        return Send({
            url: '/settle/freetime/inquiry',
            method: 'get',
            params: {
                blNo: param.blNo,
                demDetCatCd: param.demDetCatCd,
                eiCatCd: param.eiCatCd
            }
        })
    },
    sendKLNetDo (queryParams) {
      return Send({
        url: '/settle/freetime/sendKLNet' + queryParams,
        method: 'get',
        params: {}
      })
    },
    getProformaInvoice (param) {
      return Send({
          //url: 'http://localhost:9008/settle/invoice-print',
          url: '/settle/invoice-print',
          method: 'get',
          params: param
      })
    },
    getInputData (param) {
      return Send({
        url: '/settle/freetime/input-data',
        method: 'get',
        params: param
      })
    },
    getWebRequestInfo (param) {
      return Send({
        url: '/settle/freetime/web-request-data',
        method: 'get',
        params: param
      })
    },
    deleteDecInv (blNo) {
        return Send({
            url: '/settle/freetime/delete-dec-inv/' + blNo,
            method: 'post',
            data: {}
        })
    },
    insertDecInv (data) {
        return Send({
            url: '/settle/freetime/insert-dec-inv',
            method: 'post',
            data: data
        })
    },
    cancelSa206i (data) {
      return Send({
          url: '/settle/freetime/cancel-sa206i',
          method: 'put',
          data: data
      })
    },
    getTempBl (cntrNo) {
      return Send({
        url: '/settle/freetime/temp-bl',
        method: 'get',
        params: {
          cntrNo: cntrNo
        }
      })
    },
    getFreeDaySync (params) {
      return Send({
        url: '/settle/freetime/freeday-sync',
        method: 'get',
        params: {
          blNo: params.blNo,
          cntrNo: params.cntrNo,
          demSeq: params.demSeq,
          freeDds: params.freeDds,
          freeEndDt: params.freeEndDt,
          invRno: params.invRno,
          demDetCatCd: params.demDetCatCd
        }
      })
    }
}
