import Send from '../../trans.client.js'

export default {
  sendKLNetDo (data) {
    return Send({
      url: '/trans/do/' + data.blNo + '/klnet-do',
      method: 'post',
      data: data
    })
  },
  checkDoPrint (data) {
    return Send({
      url: '/trans/do/' + data.blNo + '/do-print',
      method: 'post',
      data: data
    })
  },
  //D/O 발행 상세 조회
  findDoPaymentDetail (formData) {
    return Send({
      url: '/trans/do/payment?blNoArr=' + formData.blNoArr,
      method: 'get'
    })
  },
  //B/L발행 요청
  insertDoRequest (formData) {
    return Send({
      url: '/trans/do/payment',
      method: 'post',
      data: formData
    })
  },
  //D/O 발행 화면 미지급금액 조회
  findUnpaid (blNo) {
    return Send({
      url: '/trans/do/payment/unpaid/' + blNo,
      method: 'get'
    })
  },
  //D/O 발행 상태 가져오기
  findDoPaymentStatus (blNoArr) {
    return Send({
      url: '/trans/do/payment/status?blNoArr=' + blNoArr,
      method: 'get'
    })
  },
  //위수임 체크
  selectDelegateTerm (blNoArr) {
    return Send({
      url: '/trans/do/payment/delegate?blNoArr=' + blNoArr,
      method: 'get'
    })
  },
  //위수임 메일 체크
  sendDelegateMail (blNoArr) {
    return Send({
      url: '/trans/do/payment/delegate-mail?blNoArr=' + blNoArr,
      method: 'get'
    })
  },
  //D/O 발행전 DEM/DET 체크
  demDetCheck (blNoArr) {
    return Send({
      url: '/trans/do/payment/dem-det-check?blNoArr=' + blNoArr,
      method: 'get'
    })
  },
  //프리타임 체크
  freetimeCheck (blNo) {
    return Send({
      url: '/trans/do/payment/freetime-check?blNo=' + blNo,
      method: 'get'
    })
  },
  //외상화주 여부 체크
  getShipperDmdInfo (obj) {
    return Send({
      url: '/trans/do/payment/shipper-dmd-info/' + obj.bzrgNo + '/' + obj.cstNo,
      // url: 'http://localhost:9006/trans/do/payment/shipper-dmd-info/' + obj.bzrgNo + '/' + obj.cstNo,
      method: 'get'
    })
  },
  // DO발행 요청(JP)
  insertDoRequestEtc (formData) {
    return Send({
      url: '/trans/do/payment/etc',
      method: 'post',
      data: formData
    })
  }
}
