<template>
  <div class="popup_wrap" style="width:800px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">
        {{ $t('msg.CSBL400.002') }}<!--Freetime 요청 내역(최근 2주간)-->
        <span style="position:relative; padding-left:20px; font-size:12px; color:#777777; font-size:12px; text-align:right" v-html="$t('msg.CSDBL400_M2.017')"><!-- $t('msg.CSDBL400_M2.017') FREETIME은 최종승인내역(<span class="color_red">Apply</span>) 기준으로 적용됩니다. --></span>
      </h1>
      <div id="realgridFreeTimePrint" style="width: 100%; height: 450px"></div>
      <!-- <div class="desc_list"> -->
      <div class="desc_list">
        <ul>
          <li v-html="$t('msg.CSDBL400_M2.019')"><!-- $t('msg.CSDBL400_M2.017') FREETIME은 최종승인내역(<span class="color_red">Apply</span>) 기준으로 적용됩니다. --></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import FreeTime from '@/api/rest/settle/freeTime'
let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'demDetAppNo', dataType: 'text' },
  { fieldName: 'ldCatCd', dataType: 'text' },
  { fieldName: 'demDetCatCd', dataType: 'text' },
  { fieldName: 'reqDds', dataType: 'number' },
  { fieldName: 'reqRate', dataType: 'number' },
  { fieldName: 'rcmdDds', dataType: 'number' },
  { fieldName: 'rcmdRate', dataType: 'number' },
  // { fieldName: 'reqDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
  { fieldName: 'reqDt', dataType: 'text' },
  { fieldName: 'apvStsCd', dataType: 'text' },
  { fieldName: 'print', dataType: 'text' },
  { fieldName: 'confirm', dataType: 'text' },
  { fieldName: 'apvDt', dataType: 'text' },
  { fieldName: 'appRk', dataType: 'text' },
  { fieldName: 'cmmList', dataType: 'text' }
]

const columns = [
  {
    name: 'ref_no',
    fieldName: 'demDetAppNo',
    header: { text: 'Ref No.' },
    width: 70,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return '<span style="color:#075bb9;"> ' + provider.getValue(cell.index.dataRow, 'demDetAppNo') + '</span>'
      }
    }
  },
  {
    name: 'loading_discharge',
    fieldName: 'ldCatCd',
    header: { text: 'Loading/\nDischarge' },
    width: 72,
    displayCallback: function (grid, index, value) {
      // return value === 'L' ? 'Loading' : 'Discharging'
      return value
    }
  },
  {
    name: 'dem_det',
    fieldName: 'demDetCatCd',
    header: { text: 'DEM/\nDET' },
    width: 50,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        var rtnVal = ''
        const compVal = vmApp2.getCodeNm(provider.getValue(cell.index.dataRow, 'demDetCatCd'))
        console.log('==================>' + compVal)
        if (compVal === 'DEMURRAGE') {
          rtnVal = 'DEM'
        } else if (compVal === 'DETENTION') {
          rtnVal = 'DET'
        } else if (compVal === 'Over Freetime') {
          rtnVal = 'OFC'
        } else {
          rtnVal = compVal
        }

        //return compVal
        return rtnVal
      }
    }
  },
  { name: 'request_freedays', fieldName: 'reqDds', numberFormat: '#####', header: { text: 'Freeday' }, width: 50 },
  { name: 'request_dc_rate', fieldName: 'reqRate', numberFormat: '#####', header: { text: 'D/C rate' }, width: 60 },
  { name: 'approval_freedays', fieldName: 'rcmdDds', numberFormat: '#####', header: { text: 'Freeday' }, width: 50 },
  { name: 'approval_dc_rate', fieldName: 'rcmdRate', numberFormat: '#####', header: { text: 'D/C rate' }, width: 60 },
  {
    name: 'request_date',
    fieldName: 'reqDt',
    // type: text,
    header: { text: app.$t('tit.CSBL400.004') },
    width: 70, //,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let data = cell.value
        let str = ''
        data = data.substring(0, 4) + '.' + data.substring(4, 6) + '.' + data.substring(6, 8)
        str = '<span>' + data + '</span>'
        return str
      }
    }
  },
  {
    name: 'status',
    fieldName: 'apvStsCd',
    header: { text: 'Status' },
    width: 60,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        var value = cell.value
        var rtnVal = ''
        var rtnClass = ''

        if (value === '01' || value === '02') {
          rtnVal = 'Request'
          rtnClass = 'label blue md minwid50'
        } else if (value === '03') {
          rtnVal = 'Accept'
          rtnClass = 'label green md minwid50'
        } else if (value === '04') {
          rtnVal = 'Reject'
          rtnClass = 'label red md minwid50'
        } else if (value === '05') {
          rtnVal = 'Checking'
          rtnClass = 'label blue md minwid50'
        } else if (value === '06') {
          rtnVal = 'Arranged'
          rtnClass = 'label blue md minwid50'
        } else if (value === '07') {
          rtnVal = 'Pending'
          rtnClass = 'label blue md minwid50'
        } else if (value === '10') {
          rtnVal = 'Canceled'
          rtnClass = 'label red md minwid50'
        }
        return '<span class="' + rtnClass + '">' + rtnVal + '</span>'
      }
    }
  },
  {
    name: 'print',
    fieldName: 'print',
    header: { text: app.$t('msg.ARAI200.007') },
    width: 65,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        // console.log('apvStsCd @@@ ', provider.getValue(cell.index.dataRow, 'apvStsCd'))
        // console.log('appRk @@@ ', provider.getValue(cell.index.dataRow, 'appRk'))
        if (vmApp2.parentInfo.ctrCd === 'JP') {
          if (provider.getValue(cell.index.dataRow, 'apvStsCd') === '03') {
            return '<span class="button sm">Print</span>'
          } else {
            return ''
          }
        } else {
          if (provider.getValue(cell.index.dataRow, 'apvStsCd') === '03' && provider.getValue(cell.index.dataRow, 'appRk') === '1') {
            return '<span class="button sm">Print</span>'
          } else {
            return ''
          }
        }
      }
    }
  },
  {
    name: 'confirm',
    fieldName: 'confirm',
    header: { text: 'Confirm' },
    width: 60, //86,
    styleName: 'rgsample-red-column rgsample-bold-column',
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        var confirm = ''
        if (provider.getValue(cell.index.dataRow, 'apvStsCd') === '03' && provider.getValue(cell.index.dataRow, 'appRk') === '1') {
          confirm = 'Apply'
        } else {
          confirm = ''
        }
        return '<span class="color_red"> ' + confirm + '</span>'
      }
    }
  },
  {
    name: 'approval_date',
    fieldName: 'apvDt',
    // type: text,
    header: { text: app.$t('tit.CSBL400.014') },
    width: 70, //,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let data = cell.value
        let str = ''
        if (data === null || data === '') {
          data = ''
        } else {
          if (provider.getValue(cell.index.dataRow, 'apvStsCd') === '03' && provider.getValue(cell.index.dataRow, 'appRk') === '1') {
            data = data.substring(0, 4) + '.' + data.substring(4, 6) + '.' + data.substring(6, 8)
          } else {
            data = ''
          }
        }
          str = '<span>' + data + '</span>'
        return str
      }
    }
  },
  { name: 'appRk', fieldName: 'appRk', header: { text: 'appRk' }, width: 0 },
  { name: 'cmmList', fieldName: 'cmmList', header: { text: 'cmmList' }, width: 0 }
  ]

const layout = [
  'ref_no',
  'loading_discharge',
  'dem_det',
  {
    name: 'group1',
    direction: 'horizontal',
    items: [
      'request_freedays',
      'request_dc_rate'
    ],
    header: {
      text: '요청내역'
    }
  },
  {
    name: 'group2',
    direction: 'horizontal',
    items: [
      'approval_freedays',
      'approval_dc_rate'
    ],
    header: {
      text: '승인내역'
    }
  },
  'request_date',
  'status',
  'print',
  'confirm',
  'approval_date'//,
  //'cmmList'
]

export default {
  name: 'FreeTimePrint',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          demDetCatCd: '',
          eiCatCd: ''
        }
      }
    }
  },
  data: function () {
    return {
      param: {
        blNo: '',
        demDetCatCd: '',
        eiCatCd: '',
        asFromDt: '',
        asToDt: ''
      },
      list: [{
        demDetAppNo: '11111',
        ldCatCd: '2',
        demDetCatCd: '3',
        reqDds: '4',
        reqRate: '5',
        rcmdDds: '6',
        rcmdRate: '7',
        reqDt: '20210908',
        apvStsCd: '9',
        print: '10',
        confirm: '11',
        cmmList: ''
      }]
    }
  },
  computed: {
  },
  watch: {
    list: function () {
    }
  },
  created () {
    //window.functionOutsideVue(this)
    window.vmApp2 = this
  },
  mounted: function () {
    const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgridFreeTimePrint')

    gridView.setDataSource(provider)
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: false
    })
    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    gridView.footers.visible = false

    // 그리드 필드 생성
    provider.setFields(fields)
    gridView.setColumns(columns)
    layout[3].header.text = this.$t('tit.CSBL400.002')
    layout[4].header.text = this.$t('tit.CSBL400.003')
    gridView.setColumnLayout(layout)

    gridView.onCellItemClicked = function (grid, index, clickData) {
      if (gridView.getCurrent().fieldName === 'print') {
        if ($vm.parentInfo.ctrCd === 'JP') {
          if (provider.getValue(index.dataRow, 'apvStsCd') === '03') {
            $vm.openPrintPop(provider.getValue(index.dataRow, 'demDetAppNo'))
          }
        } else {
          if (provider.getValue(index.dataRow, 'apvStsCd') === '03' && provider.getValue(index.dataRow, 'appRk') === '1') {
            $vm.openPrintPop(provider.getValue(index.dataRow, 'demDetAppNo'))
          }
        }
      }
    }
    //그리드 이미지 초기화
    gridView.registerCustomRenderer('renderer_imgbtn', {
      initContent: function (parent) {
        var span = this._span = document.createElement('span')
        span.className = 'custom_render_span'
        parent.appendChild(span)
        parent.appendChild(this._button1 = document.createElement('span'))
        parent.appendChild(this._button2 = document.createElement('span'))
      },
      canClick: function () {
        return true
      },
      clearContent: function (parent) {
        parent.innerHTML = ''
      },
      render: function (grid, model, width, height, info) {
        info = info || {}
        var span = this._span
        // text설정.
        span.textContent = model.value
        this._value = model.value
        this._button1.className = ''
        this._button2.className = ''
        //switch(model.value) {
          //case '모잠비크':
            //  this._button1.className = 'custom_search custom-hover' + (info.focused ? ' custom-focused' : '');
              //this._button2.className = 'custom_popup custom-hover' + (info.focused ? ' custom-focused' : '');
              //break;
          //case '부베 섬':
              this._button2.className = 'custom_popup custom-hover' + (info.focused ? ' custom-focused' : '')
            //  break;
      },
      click: function (event) {
        var grid = this.grid.handler
        var index = this.index.toProxy()
        event.preventDefault()
        if (event.target === this._button1) {
          alert('조회버튼: ' + this._value)
        } else if (event.target === this._button2) {
          alert('APV_STS_CD == ＇03’ and APP_RK == ‘1’ 이면 Print 가능함')
        }
      }
    })

    var options = gridView.getSortingOptions()

    options.enabled = !options.enabled
    //gridView.setSortingOptions(options)

    //조회
    $vm.searchData()
  },
  methods: {
    searchData: function () {
      this.param.blNo = this.parentInfo.blNo //'INC3421106' //prop blNo
      this.param.demDetCatCd = this.parentInfo.demDetCatCd //'01' //prop demDetCatCd ??
      this.param.eiCatCd = this.parentInfo.eiCatCd //'O' //prop eiCatCd 수출/수입
      FreeTime.getFreeTimeList(this.param).then(response => {
        this.cmmList = response.data.cmmList
        for (let i = 0; i < response.data.rstList.length; i++) {
          // response.data.rstList[i].ldCatCd = response.data.rstList[i].ldCatCd === 'L' || response.data.rstList[i].ldCatCd === 'O' ? 'Loading' : 'Discharging'
          response.data.rstList[i].ldCatCd = response.data.rstList[i].ldCatCd === 'L' ? 'Loading' : 'Discharging'
        }
        this.list = response.data.rstList

        // var clist = ''
        // for (var i = 0; i < this.cmmList.length; i++) {
        //   clist += (i + 1) === this.cmmList.length ? this.cmmList[i].cdNm : this.cmmList[i].cdNm + ','
        // }
        // this.list[0].cmmList = clist
        provider.setRows(this.list)
      }).catch(err => {
        console.log(err)
        //this.$router.push('/office-agent')
        //this.$emit('close')
      })
    },
    openPrintPop (demDetAppNo) {
     this.$emit('child', {
        type: 'openPopup',
        payload: {
          compNm: 'FreeTimePop',
          demDetAppNo: demDetAppNo
        }
      })
    },
    getCodeNm (cd) {
      console.log('getCodeNm', cd)
      const vo = (this.cmmList || []).find(tvo => tvo.cd === cd)
      return vo !== undefined ? vo.cdNm : cd
    }
  }
}
</script>

 <style scoped>

.custom_none {
  display:none;
}

.custom_render_span {
  flex: 1;
  text-align: left;
  overflow: hidden;
}

.custom_renderer > div {
  display: flex;
  align-items: center;
}

.custom_renderer .rg-renderer:hover .custom-hover {
  visibility: visible;
}

.custom_renderer .rg-renderer .custom-hover {
  visibility: visible;
}

.custom_renderer .rg-renderer .custom-focused {
  visibility: visible;
}

.custom_search {
  height: 26px;
  margin-left: 2px;
  background: url("https://docs.realgrid.com/public/images/btnImages/search_normal.png") center center no-repeat;
  flex: 0 0 45px;
}
.custom_search:hover {
  background: url("https://docs.realgrid.com/public/images/btnImages/search_hover.png") center center no-repeat;
}
.custom_search:active {
  background: url("https://docs.realgrid.com/public/images/btnImages/search_click.png") center center no-repeat;
}

.custom_popup {
  height: 26px;
  margin-left: 2px;
  background: url("https://docs.realgrid.com/public/images/btnImages/popup_normal.png") center center no-repeat;
  flex: 0 0 45px;
}
.custom_popup:hover {
  background: url("https://docs.realgrid.com/public/images/btnImages/popup_hover.png") center center no-repeat;
}
.custom_popup:active {
  background: url("https://docs.realgrid.com/public/images/btnImages/popup_click.png") center center no-repeat;
}

.rg-focus {
  /* 포커스 셀의 색깔 변경 */
  border: 2px solid #00f;
}

.rg-selection-cell {
  /* 선택 영역의 색깔 변경 */
  background: #0000ff40 !important;
  color: inherit;
}

.rg-selection {
  background: rgba(21, 139, 229, 0.2) !important;
  border: 1px solid rgba(21, 66, 229, 0.67);
}

.orange-column {
  background: #ffff00
}

.cursor {
  cursor: crosshair;
}

.orange-color{
  background: #ff8b00;
}

.rg-focused-cell{
  background: #FF00ff;
}

.rg-focused-row{
  background: #FFFF00;
}

.datepicker-inline {
  width: 200px;
}
/* 그리드 색상 & 굵기 */
.rgsample-red-column {
    color: red;
}
.rgsample-blue-column {
    color: blue;
}
.rgsample-bold-column {
    font-weight: bold;
}
 </style>
